.meya-backdrop {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5000 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.meya-backdrop-generic {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 5002 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.meya-backdrop-loading {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5004 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.meya-backdrop-loading-trans {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5004 !important;
    background-color: transparent
}

.meya-modal-loading {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: auto;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5005;
    border-radius: 3px;
    border: none;
    padding: 15px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    backdrop-filter: blur(10px);
}

.meya-modal-loading-body {
    position: relative;
    display: block;
    height: 100%;
    padding: 0px;
    justify-content: center;
    align-items: center;
}

.meya-modal-loading-header {
    font-size: 18px;
    color: #03a9f4;
}

.meya-modal-loading-content {
    font-size: 16px;
}


.meya-modal-generic {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: auto;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5004;
    border-radius: 3px;
    border: none;
    padding: 15px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    backdrop-filter: blur(10px);
}

.meya-modal-generic-body {
    position: relative;
    display: block;
    height: 100%;
    padding: 0px;
    justify-content: center;
    align-items: center;
}

.meya-modal-generic-header {
    font-size: 18px;
    color: #03a9f4;
}

.meya-modal-generic-content {
    font-size: 16px;
}

.meya-modal-btn {
    display: flex;
    justify-content: center;
    font-size: 14px;
    width: auto;
    min-width: 75px;
    height: auto;
    vertical-align: middle;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    padding: 10px;
}

.meya-modal-btn:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.meya-modal-generic.modal-success {
    border-top: 2px solid #2ecc71;
}

.meya-modal-generic-header.modal-success {
    color: #2ecc71;
}

.meya-modal-generic.modal-error {
    border-top: 2px solid #e74c3c;
}

.meya-modal-generic-header.modal-error {
    color: #e74c3c;
}

.meya-modal-generic.modal-confirm,
.meya-modal-generic.modal-warning {
    border-top: 2px solid #f39c12;
}

.meya-modal-generic-header.modal-confirm,
.meya-modal-generic-header.modal-warning {
    color: #f39c12;
}

.meya-modal-generic.modal-info,
.meya-modal-generic.modal-submitdoc,
.meya-modal-generic.modal-signup {
    border-top: 2px solid #3498db;
}

.meya-modal-generic-header.modal-info,
.meya-modal-generic-header.modal-submitdoc,
.meya-modal-generic-header.modal-signup {
    color: #3498db;
}

.meya-modal-loading.modal-chakex,
.meya-modal-generic.modal-chakex {
    animation: shake-base 5s ease-in-out;
}

@keyframes shake-base {
    0% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(2.0);
    }
}