.admin-sidbar {
    border-right: 1px solid #ccc;
    min-width: 240px;
}


.admin-menu {
    font-size: 0.875rem;
    color: #333e48;
    padding-left: 0px;
}

.admin-menu a {
    color: #333e48;
    display: block;
}

.admin-menu .dropdown-title {
    padding: 0.938rem 1.75rem;
    padding-bottom: 5px;
    width: fit-content;
    padding-left: 15px;
}

.admin-menu .dropdown-item,
.admin-menu .dropdown-toggle:not(.dropdown-title) {
    padding: 0.5rem 2.2rem;
}

.admin-menu.view-all {
    padding-bottom: .3rem;
}

.admin-menu.view-all>li:not(:first-child) {
    margin: 0 0.875rem;
}

.admin-menu.view-all>li:not(:last-child):not(:first-child) {
    border-bottom: none;
}

.admin-menu.view-all .dropdown-list li .dropdown-item,
.admin-menu.view-all .dropdown-list li .dropdown-toggle {
    border-bottom-width: 0;
}

.admin-menu.view-all .dropdown-toggle {
    padding: 0.5rem 1rem;
}

.admin-menu.view-all .dropdown-toggle::after {
    -ms-flex-order: -1;
    order: -1;
    margin-left: 0;
    margin-right: .5rem;
    color: #aeaeae;
    font-size: 0.74987rem;
}


.admin-menu .dropdown-item.active {
    font-weight: 600;
}

.admin-menu .dropdown-subtitle {
    padding: 0.138rem 0.75rem;
    border-bottom: none;
}

.admin-menu-width-limit {}

.meya-table-console-wrapper {
    overflow: auto;
}

@media (min-width: 768px) {
    .meya-table-console-wrapper {
        max-width: calc(100vw - 300px);
    }
}

.meya-table-console-wrapper table.meya-table-console {
    /* width: 100% !important; */
    /* min-width:1100px; */
}

.table.meya-table-console thead tr {
    border-top: none;
}

.table.meya-table-console thead tr th {
    border-top: none;
    font-size: 14px;
    font-weight: 400;
}

.table.meya-table-console th,
.table.meya-table-console td {
    line-height: 10px;
    vertical-align: middle;
    font-weight: 400;
    padding: 8px 8px 8px 8px;
}

.table.meya-table-console .count-column {
    width: 150px;
    text-align: right;
}

.table.meya-table-console .tool-column {
    width: 100px !important;
    text-align: right;
}

.table.meya-table-console .text-right {
    text-align: right;
}

.table-subitem {
    display: table;
    width: 100%;
    background-color: transparent;
    font-weight: 100;
    font-family: 'Kanit';
}

.table-subitem-row {
    display: table-row;
    width: 100%;
    line-height: 1.5;
}

.table-subitem-cell {
    display: table-cell;
}

td.table-data-span {
    width: 100% !important;
}

.filelist-box {
    width: 200px;
}

.userfile-item {
    height: 30px;
    cursor: pointer;
}

.userfile-item.active {
    font-weight: 600;
}

.filedisplay-box {
    position: relative;
    width: 700px;
    max-width: 700px;
    height: auto;
    padding-left: 20px;
    min-height: 500px;
}

.filedisplay-box img {
    width: 100%;
}