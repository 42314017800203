.border-bottom-primary {
    border-bottom: 2px solid #00c9a7 !important;
}

.meya-slide-container {
    width: 100%;
    padding: 2px;
}

.meya-slick-carousel {
    padding: 2px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid #ccc;
    text-align: center;
}

.meya-slick-carousel .js-slide {
    width: 100%;
    display: none;
    /* padding: 2px; */

}

.meya-slick-carousel .js-slide.active {
    width: 100%;
    display: block;
}

.meya-slick-carousel .js-slide img {
    width: auto;
    height: 320px;
    object-fit: cover;
}

.meya-slick-carousel-small {
    display: flex;
    padding: 0px 0px 0px 0px;
    gap: 5px;
}

.meya-slick-carousel-small .js-slide {
    padding: 2px;
    width: 100%;
    text-align: center;
    border: 1px solid #ccc;
    position: relative;

}

.meya-slick-carousel-small .js-slide img {
    width: auto;
    max-height: 100px;
}

.meya-slick-carousel-small .js-slide.active {
    border-bottom: 2px solid #34A6F7;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.icon-slide {
    position: absolute;
    top: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: lightgray;
    opacity: 0.5;
}

.icon-slideback {
    left: 10px;
}

.icon-slidenext {
    right: 10px;
}

.icon-slidenext,
.icon-slideback i {
    align-items: center;
    margin: auto;
    color: #fff;
}

.icon-slidenext:hover,
.icon-slideback:hover {
    opacity: 0.8;
}

.meya-slick-carousel-small .js-slide input.product-img-input {
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 999;
    opacity: 0;
    cursor: pointer;
}