.passwordreset-content {
    width: 100vw;
    height: 100vh;
}

.passwordreset-content .passwordreset-bar {
    height: 61px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    display: inline-flex;
    align-items: center;
}

.passwordreset-content .passwordreset-bar img {
    height: 60px;
    width: auto;
    margin-right: 10px;
}

.center-box {
    width: 340px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}