.bg-img-homeslide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    font-size: 20px;
    overflow: hidden;
    height: 440px;
    display: flex;
    align-items: center;
}

.text-bannner-poster.poster-title {
    font-size: 2.47525em;
    padding: 0;
    margin: 0;
}

.text-bannner-poster.poster-subtitle {
    font-size: 1.82525em;
    padding: 0;
    margin: 0;
}

.homeslide-textbox {}

@media only screen and (max-width: 1600px) {
    .bg-img-homeslide {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1199px) {
    .bg-img-homeslide {
        font-size: 16px;
        height: 360px;
    }
}

@media only screen and (max-width: 880px) {
    .bg-img-homeslide {
        font-size: 16px;
        height: 280px;
    }
}

@media only screen and (max-width: 768px) {
    .bg-img-homeslide {
        font-size: 14px;
        height: 280px;
    }
}

@media only screen and (max-width: 575px) {
    .bg-img-homeslide {
        font-size: 10px;
        height: 200px;
    }
}

@media only screen and (max-width: 479px) {
    .bg-img-homeslide {
        font-size: 10px;
    }

}

/************
14. 400px
************/
@media only screen and (max-width: 400px) {
    .bg-img-homeslide {
        font-size: 8px;
    }
}

@media only screen and (max-width: 360px) {
    .bg-img-homeslide {
        font-size: 8px;
        height: 180px;
    }
}