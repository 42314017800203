.stepbuysale {
    display: flex;
}

.stepbuysale .media {
    width: 100% !important;
}

.stepbuysale .media .u-avatar {
    position: relative;
}

.stepbuysale .media .u-avatar i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.stepbuysale .stepno {
    display: block;
}

@media only screen and (min-width: 480px) {
    .stepbuysale .media {
        width: 50% !important;
    }
}

@media only screen and (min-width: 960px) {
    .stepbuysale .media {
        width: 25% !important;
    }
    .stepbuysale .stepno {
        display: none;
    }
}