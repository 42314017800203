/* * {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
} */

/* :root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --transDur: 0.15s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

body,
button {
  color: var(--fg);
  font: 1em/1.5 "DM Sans", "Helvetica Neue", Helvetica, sans-serif;
}

body {
  background-color: var(--bg);
  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color var(--transDur);
} */

.notification {
    padding-bottom: 0.75em;
    position: fixed;
    top: 1.5em;
    right: 1.5em;
    width: 18.75em;
    max-width: calc(100% - 3em);
    transition: transform 0.15s ease-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 5000;
    background-color: transparent;
    font-family: 'Kanit' !important;
}

.notification__box,
.notification__content,
.notification__btns {
    display: flex;

}

.notification__box,
.notification__content {
    align-items: center;
}

.notification__box {
    background-color: hsl(0, 0%, 100%);
    border-radius: 0.02em;
    box-shadow: 0 0.5em 1em hsla(var(--hue), 10%, 10%, 0.1);
    height: 4em;
    transition:
        background-color var(--transDur),
        color var(--transDur);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.notification__box.flyInRight {
    animation: flyInRight 0.3s ease-out;
}

.notification__box .shake {
    animation: shake 0.5s ease-out;
}

.notification--out .notification__box {
    animation: flyOutRight 0.3s ease-out forwards;
}

.notification__box.error {
    border: 1px solid red;
}

.notification__box.warning {
    border: 1px solid darkorange;
}

.notification__box.success {
    border: 1px solid limegreen;
}

.notification__box.message {
    border: 1px solid black;
}

.notification__icon.message {
    color: black;
}

.notification__content {
    padding: 0.375em 1em;
    width: 100%;
    height: 100%;
}

.notification__icon {
    flex-shrink: 0;
    margin-right: 0.75em;
    width: 2em;
    height: 2em;
}

.notification__icon.error {
    color: red;
}

.notification__icon.warning {
    color: darkorange;
}

.notification__icon.success {
    color: limegreen;
}

.notification__icon.message {
    color: black;
}


.notification__icon i {
    width: 100%;
    height: auto;
    font-size: 28px;
}

.notification__text {
    line-height: 1.333;
}

.notification__text-title {
    font-size: 0.75em;
    font-weight: bold;
}

.notification__text-subtitle {
    font-size: 0.75em;
    opacity: 0.9;
}

.notification__btns {

    flex-direction: column;
    flex-shrink: 0;
    min-width: 4em;
    height: 100%;
    transition: box-shadow var(--transDur);
}

.notification__btn {
    background-color: transparent;

    font-size: 0.75em;
    line-height: 1;
    font-weight: 500;
    height: 100%;
    padding: 0 0.5rem;
    transition:
        background-color var(--transDur),
        color var(--transDur);
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.notification__btn-text {
    display: inline-block;
    pointer-events: none;
}

.notification__btn:first-of-type {
    border-radius: 0 0.02rem 0 0;
}

.notification__btn:last-of-type {
    border-radius: 0 0 0.02rem 0;
}

.notification__btn:only-child {
    border-radius: 0 0.02rem 0.02rem 0;
}

.notification__btn+.notification__btn {
    box-shadow: 0 -1px 0 hsla(var(--hue), 10%, 10%, 0.15);
    font-weight: 400;
}

.notification__btn:active,
.notification__btn:focus {}

.notification__btn:focus {
    outline: transparent;
}

.notification button {
    border: none;
    border: 1px solid rgba(0, 0, 0, .07);
}

@supports selector(:focus-visible) {
    .notification__btn:focus {
        background-color: transparent;
    }

    .notification__btn:focus-visible,
    .notification__btn:active {}
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    .notification__box {
        background-color: hsl(var(--hue), 10%, 30%);
    }

    .notification__btns {
        box-shadow: -1px 0 0 hsla(var(--hue), 10%, 90%, 0.15);
    }

    .notification__btn+.notification__btn {
        box-shadow: 0 -1px 0 hsla(var(--hue), 10%, 90%, 0.15);
    }



    @supports selector(:focus-visible) {
        .notification__btn:focus {
            background-color: transparent;
        }

        .notification__btn:focus-visible,
        .notification__btn:active {
            background-color: hsl(var(--hue), 10%, 35%);
        }
    }
}

/* Animations */
@keyframes flyInRight {
    from {
        transform: translateX(calc(100% + 1.5em));
    }

    to {
        transform: translateX(0);
    }
}

@keyframes flyOutRight {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(100% + 1.5em));
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}