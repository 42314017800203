.search-bar-auto {
    /* display: none; */
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    box-sizing: border-box;
    margin-top: -1px;
    overflow-y: none;
    position: absolute;
    width: -moz-calc(100% - 30px);
    width: calc(100% - 30px);
    z-index: 5001;
    -webkit-overflow-scrolling: touch;
    min-height: 255px;
    padding: 5px;
    border-radius: 4px;
    margin-top: 0px;
    padding-left: 15px;
    padding-bottom: 15px;
    max-height: 355px;

    max-height: 65vh;
    overflow-y: scroll;
    overflow-x: none;
}

.search-bar-auto-wrapper {
    overflow: auto;
}

.search-bar-auto-item {
    width: 100%;
    height: 30px;
    font-family: 'Kanit';
    display: flex;
    vertical-align: middle;
    align-items: center;
    color: #696969;

}

.search-bar-auto-item i {
    font-size: 12px;
    color: #696969;
}

.search-bar-auto-item span {
    margin-left: 5px;
    margin-right: 5px;
    color: dodgerblue;
}

.search-bar-auto-item label {
    font-size: 12px;
    color: #808080;
}

.search-bar-auto .search-bar-auto-item:hover {
    cursor: pointer;
    background-color: #F8F9F9;
}