/*------------------------------------
  Tables
------------------------------------*/
.subprice-table .table th,
.subprice-table .table td,
.wishlist-table .table th,
.wishlist-table .table td {
    border-width: 0;
    vertical-align: middle;
    padding: .75rem;
}

.subprice-table .table thead th,
.wishlist-table .table thead th {
    border-bottom-width: 1px;
    color: #747474;
}

.subprice-table table th,
.wishlist-table table th {
    font-weight: 400;
}

.subprice-table .table,
.wishlist-table .table {
    margin-bottom: 0;
}

.subprice-table .table tbody tr:first-child td,
.wishlist-table .table tbody tr:first-child td {
    /* padding-top: 2.188rem; */
}

.subprice-table .table tbody tr td,
.wishlist-table .table tbody tr td {
    padding: 1.125rem 0.75rem;
}

.subprice-table .table td,
.wishlist-table .table td {
    font-size: 1.06225rem;
}

@media (max-width: 767.98px) {

    .subprice-table .table thead,
    .wishlist-table .table thead {
        display: none;
    }
}

@media (max-width: 767.98px) {

    .subprice-table .table tr,
    .wishlist-table .table tr {
        display: block;
    }

    .subprice-table .table tr td,
    .wishlist-table .table tr td {
        text-align: right;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .subprice-table .table tr td::before,
    .wishlist-table .table tr td::before {
        content: attr(data-title) ": ";
        font-weight: 700;
    }
}

@media (max-width: 767.98px) {

    .subprice-table .table tbody tr td:first-child,
    .wishlist-table .table tbody tr td:first-child {
        /* -ms-flex-pack: end;
        justify-content: flex-end; */
    }

    .subprice-table .table tbody tr td:first-child::before,
    .wishlist-table .table tbody tr td:first-child::before {
        /* display: none; */
    }

    .subprice-table .table tbody tr:not(:first-child):not(:last-child) td:first-child,
    .wishlist-table .table tbody tr:not(:first-child):not(:last-child) td:first-child {
        border-top: 1px solid #dddddd;
        margin-top: 15px;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.order-expanded-area {
    animation-duration: 500ms;
    z-index: 1;
}