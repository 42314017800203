.cover-template {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 10002;
    border-radius: 0px;
    border: none;
    padding: 0;
    background-size: contain;
    background-repeat: no-repeat;
    align-content: center;
    text-align: center;
    align-items: center;
}

.cover-template-paper {
    display: block;
    border-radius: 5px;
    border: none;
    padding: 0;
    background-size: contain;
    background-repeat: no-repeat;
    align-content: center;
    text-align: center;
    align-items: center;
    width: 4in;
    height: 6in;
}

.packagelist-template {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 10002;
    border-radius: 0px;
    border: none;
    padding: 10px;
    max-height: 90vh !important;
    max-width: 90vw !important;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    margin-top: -1px;
    overflow-y: none;
    -webkit-overflow-scrolling: touch;
    min-height: 255px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.packagelist-template-toolbar {
    height: 40px !important;
    margin-bottom: 5px;
}

.packagelist-template-paper-wrapper {
    padding: 15px;
    background-color: #e5e7e9;
    overflow-y: scroll;
    max-height: calc(90vh - 65px) !important;
    -webkit-overflow-scrolling: touch;
    border: 1px solid #ccc;
}

.packagelist-template-paper {
    position: relative;
    border: none;
    padding: 10px;
    width: 21cm;
    height: 29.7cm;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    background-color: #fff;
}

.packagelist-template-paper-header {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 0px;
}

img.packagelist-logo {
    height: 50px;
    width: auto;
}

.packagelist-compapyname {
    position: absolute;
    top: 5px;
    left: 50px;
    height: 50px;
    width: auto;
    font-family: 'Kanit';
}

.packagelist-system {
    position: absolute;
    top: 18px;
    left: 50px;
    height: 50px;
    width: auto;
    font-family: 'Kanit';
}

.packagelist-ref-table {
    display: table;
    width: 100%;
    font-family: 'Kanit';
}

.packagelist-ref-table-row {
    display: table-row;
}


.packagelist-ref-table-cell {
    display: table-cell;
    width: 80%;
    padding: 0px 10px 0px 10px;
}

.packagelist-ref-table-cell-first {
    display: table-cell;
    max-width: 260px !important;
}

.table.packagelist-item-table {
    margin-top: 10px;
    border-top: none !important;
    border-bottom: 1px solid #f2f3f4;
}

.packagelist-item-row-head td {
    border-top: none !important;
}

.table.packagelist-item-table tbody td {
    padding: 0px 10px 0px 10px;
}

.table td.packagelist-item-name {
    font-size: 12px !important;
}

.packagelist-remark {
    font-size: 12px;
    position: absolute;
    top: 1085px;
}

#packagelist-content {
    display: none !important;
}