.card-buysale {
    background: #fff;
    border-radius: 1px;
    overflow: hidden;
    margin-bottom: 30px;
    /* -webkit-box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12); */
    -webkit-transition: 0.1s;
    transition: 0.1s;
}

.card-buysale:hover {
    -webkit-box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
}

.card-buysale .card-buysale-img {
    display: block;
    position: relative;
}

.card-buysale .card-buysale-img img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}


.card-buysale .card-buysale-content {
    padding: 15px;
}

@media (min-width: 992px) {
    .card-buysale-vertical {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        height: 235px;
    }

    .card-buysale-vertical .card-buysale-img img {
        width: 300px;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}