.tab-list:not(.tab-list-active) {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    font-family: 'Kanit';
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.3rem 0.75rem;
    cursor: pointer;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    border-bottom: none !important;
    cursor: pointer;
}

.tabs {
    height: 100%;
    flex-flow: row wrap;
}

.tabs .tab-content {
    padding-top: 10px;
    border-left: 1px solid #ccc;
    height: 100%;
}