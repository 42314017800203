.delivery-timeline-box {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 460px;
    height: 610px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
    font-family: 'Kanit';
}

.delivery-timeline-box-header {
    height: 50px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    border-bottom: 1px solid #ecedf2;
    font-size: 18px;
}

.timeline-box-header-title {
    margin-bottom: 0px;
    padding-bottom: opx;
}

.timeline-box-header-order {
    padding-left: 20px;
    font-size: 14px;
    margin: 0;
}

.delivery-timeline-box-wrapper {
    position: absolute;
    top: 50px;
    width: 460px;
    padding: 10px !important;
    height: calc(100% - 50px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.vertical-timeline p {
    margin: 0;
}

.vertical-timeline {
    width: 100%;
    position: relative;
    padding: 1.0rem 0 1rem;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 17px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
    border-radius: .25rem;
}

.vertical-timeline-element {
    position: relative;
    margin: 0 0 0.7rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 .8s;
}

.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 10px;
}

.vertical-timeline-element-icon .badge-dot-xl {
    box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
    width: 18px;
    height: 18px;
    position: relative;
}

.badge:empty {
    display: none;
}


.badge-dot-xl::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: .25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: #fff;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 40px;
    font-size: .8rem;
}

.vertical-timeline-element-content .timeline-title {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 .2rem;
    padding: 2px 0 0;
    font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: -90px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: #adb5bd;
    font-size: .7619rem;
    white-space: nowrap;
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}