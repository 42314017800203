.sidebar-backdrop {
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5002 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.sidebar-word {
    display: block;
    position: sticky;
    top: 100vh;
    left: 3px;
}