.myshop-sidebar {
    border-right: 1px solid #ccc;
    min-width: 240px;
    max-width: 240px;
}

.myshop-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.flex-sameheight {
    display: flex;
    flex-flow: row wrap;
}

.price-table {
    display: table;
    width: 100%;
}

.price-table-row {
    display: table-row
}


.price-table-cell {
    display: table-cell;
    padding: 5px;
}

.price-table-cell.price-remove i {
    font-size: 12px;
}

.btn-addprice {
    cursor: pointer;
    color: #34A6F7;
}

.btn-editprice {
    cursor: pointer;
}


.table.table-console>tbody>tr:nth-child(even) {
    background: #eee;
}

.myshop-table tr {
    border-bottom: 1px solid #ecedf2;
}

.table .column-img {
    display: flex;
    width: 100%;
}

.modal-body .img-wrapper,
.myshop-table .img-wrapper {
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
}

.modal-body .img-wrapper img,
.table .column-img .img-wrapper img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-right: 10px;
}

.new-product .img-wrapper {
    width: 140px;
    height: 140px;
    max-width: 140px;
    max-height: 140px;
    margin-right: 15px;
}

.new-product .img-wrapper img {
    max-width: 140px;
    max-height: 140px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-right: 10px;
}

.new-product .product-detail,
.modal-body .product-detail,
.myshop-table .product-detail {
    padding: 5px;
}

.myshop-table .product-detail .product-desc {
    font-size: 12px;
}

.flex-between {
    position: relative;
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

.divfit {
    margin: 0 auto;
}

.myshop-table {
    display: table;
    width: 100%;
}

.myshop-table thead th {
    border: none;
    padding-bottom: 2px;
    font-weight: 300;
}

.myshop-table .pricelist-row {
    line-height: 10px;
    border: none;
}

.myshop-table .pricelist-row td {
    border: none;
}

.myshop-image {
    width: 240px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    margin-right: 15px;
}

.width-400px {
    width: 400px;
}

.auto-search-price {
    overflow: auto;
    position: relative;
    padding: 10px 10px 10px 10px;
    display: block;
    overflow: auto;
    max-height: 380px;
}

.flex-samehi {
    -webkit-flex: 1;
    /* Safari */
    -ms-flex: 1;
    /* IE 10 */
    flex: 1;
    /* Standard syntax */
}

.auto-search-price-item {
    display: list-item;
    padding: 0px 0px 0px 0px;
}

.auto-search-price-item p {
    margin: 0;
}

span.myshop-indicator {
    display: inline-block;
    padding: 0.25em 0.6em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.1125rem;
    transition: all 0.2s ease-in-out;
    background-color: #34A6F7;
    color: #fff;
}

.btn-meya-action {
    border: none;
    color: white;
    padding: 3px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    width: auto;
    min-width: 50px;
}

.btn-meya-action.btn-warning {
    color: black;
}

.btn-meya-action.btn-danger {
    color: white;
}

.meya-pagination-container {
    padding-left: 5px;
    display: flex;
    height: 100%;
}


ul[role="navigation"] {
    /* Use flex boxes to align li items */
    display: flex;
    flex-flow: wrap;
    column-gap: 2px;
    /* Remove default padding from major browsers */
    padding: 0;
    /* Hide the default decorations for li items */
    list-style: none;
    border: 1px solid #ecedf2;
}

ul[role="navigation"]>li {}

ul[role="navigation"]>li>a {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 40px;
    min-height: 40px;
    padding: 0;
    margin: auto;
    cursor: pointer;
    color: #85929e !important;
}

ul[role="navigation"]>li:hover {
    background-color: #e5e8e8;
}

ul[role="navigation"]>li.selected>a {
    background-color: #34A6F7;
    color: #fff !important;
}

.modal-deliver-booking {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 13.4cm;
    height: auto;
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
}

.modal-deliver-booking-wrapper {
    position: relative;
    height: 100%;
    padding: 10px;
}

.meya-content-100p {
    position: relative;
    height: 100%;

}

.return-attach-input-wrapper {
    position: relative;
}

.return-attach-input-wrapper input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 55px;
    height: 20px;

}

input[type=file]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
}

.return-attach-btn {
    cursor: pointer !important;
}

img.return-attach-preview {
    width: 80px;
    height: 80px;
}

img.return-attach-preview:hover {
    cursor: pointer;
}

.modal.meya-overide {
    z-index: 1002 !important;
}

.meya-overide-backdrop {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1001 !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.nav-link.u-header__nav-link.u-header__nav-link-toggle {
    cursor: pointer;
}