.marketplace-sidebar {
    min-width: 200px;
}

.flex-container {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
}

.flex-container>div.flex-box {
    margin: 0px;
    padding: 5px;
    font-size: 20px;
    width: 16.666%;
}

.market-group-header {
    /* background-color: #F2F3F4; */
    margin: 2px;
    padding: 0px 0px 0px 0px;
    margin-left: 0px;
    border-radius: 2px;
    border-bottom: 1px solid #ccc;
    padding-left: 0px;
}

p.limit-display:hover {
    cursor: pointer;
}

input[type="checkbox"],
input[type="checkbox"]+label {
    cursor: pointer;
}

.market-price-table {
    display: table;
    width: 100%;
}

.market-price-table-row {
    display: table-row
}


.market-price-table-cell {
    display: table-cell;
    width: 20%;
    padding: 5px;
    max-width: 25%;
    min-width: 25%;
}

.market-avai-table {
    display: table;
    width: auto;
    padding: 0;
}

.market-avai-table-row {
    display: table-row
}

.market-avai-table-cell {
    display: table-cell;
    padding: 5px;
}

.market-avai-table-cell.c1 {
    min-width: 140px;
}

.market-avai-table-cell.c2 {
    width: auto;
}

.market-avai-table-cell.c3 {
    width: 80px;
    font-weight: 600;
}

/* Chrome, Safari, Edge, Opera */
input.none-arrow::-webkit-outer-spin-button,
input.none-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].none-arrow {
    -moz-appearance: textfield;
}

.market-icon-slide {
    position: absolute;
    top: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: lightgray;
    opacity: 0.5;
    z-index: 500;
}

.market-icon-slideback {
    left: 10px;
}

.market-icon-slidenext {
    right: 10px;
}

.market-icon-slidenext,
.market-icon-slideback i {
    align-items: center;
    margin: auto;
    color: #fff;
}

.market-icon-slidenext:hover,
.market-icon-slideback:hover {
    opacity: 0.8;
}

.shop-banner {
    width: 100%;
    border-left: 2px solid #34A6F7;
}

.card-compare {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
    padding: 10px;
    display: block;
    padding-bottom: 16px;
    color: #383838;
}

.card-compare-shopname {
    font-size: 14px;
}

.card-compare-province {
    font-size: 14px;
    color: #747474;
    border-bottom: 1px solid #ccc;
}

.card-compare-expire {
    font-size: 12px;
}

.card-compare-price {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
}

.card-compare-price span {
    font-size: 18px;
    color: #747474;
}

.js-plus.btn,
.js-minus.btn {
    cursor: pointer !important;
}

.stamp {
    transform: rotate(12deg);
    color: #555;
    font-size: 3rem;
    font-weight: 700;
    border: 0.25rem solid #555;
    display: inline-block;
    padding: 0.25rem 1rem;
    text-transform: uppercase;
    border-radius: 1rem;
    font-family: 'Courier';
    -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;
}

.product-blank {
    color: #D23;
    border: 0.5rem double #D23;
    transform: rotate(-3deg);
    -webkit-mask-position: 2rem 3rem;
    font-size: 2rem;
}

.stamp.product-blank {
    position: absolute;
    top: 15px;
    left: 15px;
}

.inputnumeric-alert {
    border: 2px solid #f1c40f;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.noproductdisplay {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: auto;
    background-color: #fff;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
    justify-content: center;
    font-family: 'Kanit';
    font-size: 16px;
    vertical-align: middle;
    align-items: center;
}

.moreproductdisplay {
    display: block;
    height: auto;
    background-color: #fff;
    margin-top: 15px;
    border-radius: 5px;
    border: none;
    padding: 0;
    justify-content: center;
    font-family: 'Kanit';
    font-size: 16px;
    align-items: center;
}