.form-group .product-option {
  /* display: none; */
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 355px;
  overflow-y: none;
  position: absolute;
  width: -moz-calc(100% - 30px);
  width: calc(100% - 30px);
  z-index: 500;
  -webkit-overflow-scrolling: touch;
  min-height: 255px;
  padding: 5px;
}

.form-group .product-option {
  max-height: 65vh;
  overflow-y: scroll;
  padding: 5px;
  overflow-x: none;
}

.product-option .search-item:hover {
  cursor: pointer;
  background-color: #F8F9F9;
}
.product-option .search-item.active {
  background-color: #76d7c4;
}