.input-group-icon {
  position: absolute;
  right: 0px;
  padding: 10px;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
}

.form-control-sm.active {
  border: 1px solid red;
}

.mouse-pointer {
  cursor: pointer;
}


