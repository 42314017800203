.footer {
    display: block;
    position: sticky;
    top: 100vh;
}

.footer-navigate {
    min-width: 110px;
}

.footer-contact {
    min-width: 190px;
}

.footer-right-box {
    margin-top: 0px;
    padding-top: 0px;
}

.footer-right-box {
    display: flex;
}

@media only screen and (max-width: 360px) {
    /* .footer-right-box {
        display: block;
    } */
}

.footer-word {
    max-width: 350px;
}

@media (min-width: 768px) {
    .footer-navigate {
        min-width: 150px;
    }

    .footer-contact {
        min-width: 200px;
    }
}