.payment-bookbank {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 13.4cm;
    height: auto;
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
}

.payment-bookbank-wrapper {
    position: relative;
    height: 100%;
    padding: 10px;
}

.payment-bookbank-img-wrapper img {
    height: 100px;
    width: auto;
    border-radius: 3px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.promtpay-thaiqr-box {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 10.4cm;
    height: 210px;
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
}

.promtpay-thaiqr-wrapper {
    position: absolute;
    top: 0px;
    width: 10.4cm;
    padding: 10px;
    height: 100%;
}

.promtpay-thaiqr-header {
    font-size: 18px;
}

.promtpay-thaiqr-word1 {
    font-size: 16px;
}

.promtpay-thaiqr-word2 {
    font-size: 14px;
}

.promtpay-thaiqr-link {
    margin-top: 10px;
}

.promtpay-thaiqr-link span {
    font-weight: 600;
    font-size: 16px;
    color: darkturquoise;
    cursor: pointer;
}

.promtpay-thaiqr-btncancel {
    background-color: #e74c3c;
    color: #fff;
    border-radius: 3px;
    width: 100px;
    height: 40px;
    margin-top: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promtpay-thaiqr-btnclose {
    background-color: #f4f6f7;
    color: #34495e;
    border-radius: 3px;
    width: 100px;
    height: 40px;
    margin-top: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promtpay-thaiqr-autoclose {
    position: absolute;
    bottom: 0px;
    font-size: 12px;
}

.repayment-box {
    display: flex;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 10.4cm;
    height: auto;
    background-color: #fff;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    z-index: 5001;
    border-radius: 5px;
    border: none;
    padding: 0;
}

.repayment-box-wrapper {
    position: relative;
    top: 0px;
    width: 10.4cm;
    padding: 10px;
    height: 100%;
}