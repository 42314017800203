.box-policy {
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    width: calc(100vw - 20px);
    max-width: 700px;
    border-radius: 1%;
    float: right;
    margin-right: 20px;
    padding: 20px;
    padding-top: 20px;
    z-index: 5001;
    font-family: 'Kanit', sans-serif;
}

.box-forgot {
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    width: calc(100vw - 20px);
    max-width: 500px;
    min-height: 280px;
    border-radius: 1%;
    float: right;
    margin-right: 20px;
    padding: 40px;
    padding-top: 25px;
    z-index: 5001;
    font-family: 'Kanit', sans-serif;
}

.box-signin {
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    width: calc(100vw - 20px);
    max-width: 500px;
    min-height: 560px;
    border-radius: 1%;
    float: right;
    margin-right: 20px;
    padding: 40px;
    padding-top: 25px;
    z-index: 5001;
    font-family: 'Kanit', sans-serif;
}

.box-signup {
    background-color: #FFFFFF;
    box-shadow: 0 2px 6px -2px rgba(0, 0, 0, .16), 0 2px 6px 0 rgba(0, 0, 0, .12);
    width: calc(100vw - 20px);
    max-width: 800px;
    min-height: 675px;
    max-height: 85vh !important;
    border-radius: 1%;
    margin-right: 20px;
    padding: 40px;
    padding-top: 25px;
    margin: 0 auto;
    display: block;
    z-index: 5001;
    font-family: 'Kanit', sans-serif;

    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 400px) {
    .box-signup {
        min-height: 475px;
        padding: 15px;
    }
}

.box-signup-bottom {
    margin-top: auto;
}

.box-policy .box-term-wrapper {
    min-height: 280px;
    max-height: 80vh;
    overflow: auto;
    position: relative;
    padding: 10px 10px 10px 10px;
}

.policy-list {
    display: block;
}

.policy-item {
    display: list-item;
}

.box-policy .closeicon,
.box-forgot .closeicon,
.box-signin .closeicon,
.box-signup .closeicon {
    position: relative;
    cursor: pointer;
}

.box-policy .closeicon i,
.box-forgot .closeicon i,
.box-signin .closeicon i,
.box-signup .closeicon i {
    font-size: 24px;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #525766
}

.box-policy h6,
.box-forgot h6,
.box-signin h6,
.box-signup h6 {
    font-size: 30px;
    font-weight: 400;
    color: #34A6F7;
    margin-bottom: 0;
    margin-top: 0px;
}

.box-policy h6 {
    font-size: 24px;
}

.box-forgot p,
.box-signin p,
.box-signup p {
    font-size: 16px;
    font-weight: 400;
    color: black;
    margin-bottom: 0;
}

.box-forgot label,
.box-signin label,
.box-signup label {
    font-size: 16px;
    font-weight: 400;
    color: black;
}

.box-signin .form-check {
    padding-left: 20px;
    float: none;
    margin: 0 auto;
    display: block;
    margin-left: 0;
}

.box-signin .form-check label {
    margin-left: 0px;
    padding-left: 0;
}

.box-forgot .btn-center,
.box-signin .btn-center,
.box-signup .btn-center {
    margin: 0 auto;
    display: block;
    height: 50px;
    width: 80%;
    padding: 0;
}

.box-signin .btn-center img.google-logo {
    height: 40px;
}

.box-signin .btn-center span {
    font-weight: 400;
}

.box-signin .noaccount a,
.box-signup .noaccount a {
    color: #34A6F7;
    border: none;
}

.box-signin .noaccount a:hover,
.box-signup .noaccount a:hover {
    color: #28B463;
    background-color: white;
}

.box-signin .passwordmanage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 10px;
}

.box-signin .passwordmanage a {
    padding: 0;
    margin: 0;
    color: #34A6F7;
    font-weight: 400;
}

.box-signup .agreeterm {
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 15px;

}

.box-signup .agreeterm label {
    font-family: 'Inter', sans-serif;
}

.box-signup .agreeterm .form-check {
    margin: 0 auto;
    display: block;
}

.box-signup .agreeterm span {
    color: #34A6F7;
    cursor: pointer;
}

.box-signup #button-back:hover {
    cursor: pointer;
}

.signup-page {
    min-height: 285px;
}

.opt-mobile-input {
    width: 400px;
    margin: 0 auto;
    display: block;
}

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;

    &:before {
        content: '';
        background: linear-gradient(to right, transparent, #818078, transparent);
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }

    &:after {
        content: attr(data-content);
        position: relative;
        display: inline-block;
        color: black;

        padding: 0 .5em;
        line-height: 1.5em;

        color: #818078;
        background-color: #fcfcfa;
    }
}

.progressbar-wrapper {
    background: #fff;
    width: 80%;
    padding-top: 0px;
    padding-bottom: 5px;
    margin: 0 auto;
    display: block;
    margin-bottom: 30px;
}

.f1-steps {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
}

.f1-progress {
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ddd;
}

.f1-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background: #2ECC71;
}

.f1-step {
    position: relative;
    float: left;
    width: 25%;
    padding: 0 5px;
}

.f1-step-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-top: 5px;
    background: #ddd;
    font-size: 18px;
    color: #fff;
    line-height: 40px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;

    margin-left: 0;
    margin-right: 0;
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 0;
}

.f1-step-icon {
    position: relative;
    display: flex;
    text-align: center;
}

.f1-step i {
    vertical-align: middle;
    margin: 0;

    margin: auto;
    text-align: center;
    vertical-align: middle;
}

.f1-step.active .f1-step-icon {
    width: 40px;
    height: 40px;
    margin-top: 0;
    background: #2ECC71;
    font-size: 18px;
    line-height: 40px;
}

.f1-step p {
    color: #ccc;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
}

.f1-step.active p {
    color: #2ECC71;
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control::file-selector-button {
    padding: .55rem 1rem;
    margin: -.55rem -1rem;
    margin-inline-end: 1rem;
    color: #7a878e;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2
}

.form-control::-webkit-file-upload-button {
    padding: .55rem 1rem;
    margin: -.55rem -1rem;
    margin-inline-end: 1rem;
    color: #7a878e;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, box-shadow .25s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control-sm {
    min-height: calc(1.5em + .9rem + 2px);
    padding: .45rem .95rem;
    font-size: .65625rem;
    border-radius: .3125rem
}

.form-control-sm::file-selector-button {
    padding: .45rem .95rem;
    margin: -.45rem -.95rem;
    margin-inline-end: .95rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .45rem .95rem;
    margin: -.45rem -.95rem;
    margin-inline-end: .95rem
}


.btn.meya-btn-primary {
    background-color: #34A6F7;
    color: #ffffff;

}

.btn.meya-btn-primary-outline {
    background-color: #ffffff;
    color: #34A6F7;
    border: 1px solid #34A6F7;
}

.btn.meya-btn-clear {
    background-color: #ffffff;
    color: black;
    border: 1px solid #ABB2B9;
}

.btn.meya-btn-primary-outline:hover {
    background: #34A6F7 !important;
    color: #ffffff;
}

.btn.meya-btn-primary:hover {
    background: #3d91cc !important;
    color: #ffffff;
}

.btn.meya-btn-clear:hover {
    background: #3d91cc !important;
    color: #ffffff;
    border: 1px solid #34A6F7;
}

.input-group.password-group {
    border: 1px solid #dddddd;
    padding: 0px;
}

.input-group .inp-password {}

.input-group .password-toggle-icon {
    position: absolute;
    right: 10px;
    z-index: 900;
}

.input-group .password-toggle-icon i {
    cursor: pointer;
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}